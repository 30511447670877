import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button, FloatButton } from 'antd';
import { useRouter } from 'next/router';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { imgUrl } from '@/shared/utils';

const RegisterContent = ({ isIntersecting = false }: { isIntersecting?: boolean }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const onDashboardClick = () => {
    router.push(`/dashboard?alertType=1`);
    ga_logEvent(EventName.home_ADD_ACCOUNT);
  };

  const getContent = useMemo(() => {
    return (
      <div>
        {!isIntersecting && (
          <FloatButton
            type="primary"
            className={styles.floatBtnContent}
            shape="square"
            style={{ right: 32 }}
            description={t('ADD ACCOUNT')}
            onClick={onDashboardClick}
          />
        )}

        <div
          className={styles.content}
          style={{
            backgroundImage: `url(${imgUrl(
              'https://res-gpts.pumpsoul.com/img/sign-up-icon.png',
              'mid',
            )})`,
          }}
        >
          <p className={styles.title}>{t('YourUltimateInstagramActivityTracker')}</p>
          <p className={styles.desc}>{t('NeedToKnowMoreAboutSomeoneSpecial_Desc')}</p>
          <Button type="primary" className={styles.signUpBtn} onClick={onDashboardClick}>
            {t('ADD ACCOUNT')}
          </Button>
        </div>
      </div>
    );
  }, [onDashboardClick]);

  return <>{getContent}</>;
};

export default RegisterContent;
